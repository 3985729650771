import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: 'app.component.html',
})
export class AppComponent {
  title = 'Bacart-CPL-FrontEnd';
}
