import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {SnackbarService} from "@app/shared/services/snackbar.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _router = inject(Router);
  private _snackbar = inject(SnackbarService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | any) => {
        if (error.status === 403) {
          this._router.navigate(['/login']);
          return throwError(() => error);
        } else {
          if (request.headers.has('disable_snackbar') && request.headers.get('disable_snackbar') === 'true') {
            return throwError(() => error);
          }
          const message = 'Unknown error occurred, kindly retry.';
          this._snackbar.show({message: error?.error?.exception?.message || message, status: 'danger'});
          return throwError(() => error);
        }
      })
    );
  }
}
