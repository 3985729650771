export function checkIfTokenIsExpired(token: string) {
  try {
    const payloadBase64 = token.split('.')[1];
    if (!payloadBase64) {
      throw new Error('Invalid token format');
    }

    const decodedPayload = atob(payloadBase64);
    const payload = JSON.parse(decodedPayload);

    if (!payload.exp) {
      throw new Error('Token does not contain an expiration claim');
    }

    const expirationTime = payload.exp * 1000;
    const currentTime = Date.now();

    const timeLeft = expirationTime - currentTime;

    return {
      isExpired: currentTime >= expirationTime,
      isLessThanFiveMinLeft:
        timeLeft < 5 * 60 * 1000 && timeLeft > 5 * 60 * 1000 - 5 * 1000,
    };
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return {
      isExpired: true,
    };
  }
}
