import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

@Injectable({providedIn: "root"})
class AuthGuard {
  constructor(
    private router: Router
  ) { }

  canActivate(isCPL: boolean = true): boolean | UrlTree {
    if (localStorage.getItem('accessToken')) {
      return isCPL ? true : this.router.createUrlTree(['/']);
    } else {
      return isCPL ? this.router.createUrlTree(['/auth']) : true;
    }
  }
}



export const jwtGuardCanActivateCpl: CanActivateFn = () => inject(AuthGuard).canActivate();
export const jwtGuardCanActivateLogin: CanActivateFn = () => inject(AuthGuard).canActivate(false);
