import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../constants';
import { inject } from '@angular/core';
import { AuthService } from '@app/core/services';
import { checkIfTokenIsExpired } from '@app/core/functions/check-auth-token-expiration.function';
import { SnackbarService } from '@app/shared/services/snackbar.service';

export class JwtInterceptor implements HttpInterceptor {
  private _authService = inject(AuthService);
  private _snackbar = inject(SnackbarService);
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const access_token = Constants.ACCESS_TOKEN()?.replaceAll('"', '');
    const isTokenRequired = req.context.get(Constants.TOKEN_REQUIRED);
    if (access_token && isTokenRequired) {
      this._startPeriodicTokenCheck(access_token, 5000);
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${access_token}`,
          'ngrok-skip-browser-warning': '69420',
        },
      });
    }
    return next.handle(req);
  }

  private _startPeriodicTokenCheck(token: string, intervalMs: number) {
    const intervalId = setInterval(() => {
      const result = checkIfTokenIsExpired(token);
      if (result.isLessThanFiveMinLeft) {
        // todo: temporarily disabled
        // this._snackbar.show({
        //   message: 'You are about to logout in 5 minutes.',
        //   status: 'warning',
        // });
      }
      if (result.isExpired) {
        clearInterval(intervalId);
        this._authService.logout();
      }
    }, intervalMs);
  }
}
