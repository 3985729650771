import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {mergeMap, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '@app/core/services';
import {Router} from "@angular/router";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private _authService = inject(AuthService);
  private _router = inject(Router);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !!this._authService.getRefreshToken()) {
          if (req.url.includes('refresh-token')) {
            this._redirectToLogin();
          } else {
            return this._handle401(req, next);
          }
        } else if (error.status === 401) {
          this._redirectToLogin();
        }
        return throwError(() => error);
      })
    );
  }

  private _handle401(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this._authService.refreshAccessToken().pipe(
      mergeMap(newToken => {
        if (newToken) {
          req = req.clone({setHeaders: {Authorization: `Bearer ${newToken}`}})
          return next.handle(req);
        } else {
          this._redirectToLogin();
        }
        return throwError(() => 'Unauthorized access token');
      }),
      catchError(err => throwError(() => `Error during refresh token. ${err}`))
    );
  }

  private _redirectToLogin() {
    this._authService.clearToken();
    window.location.href = '/';
  }
}
